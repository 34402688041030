import { createApp, h } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import i18n from "./i18n";
import VueMeta from "vue-meta";
import * as Sentry from "@sentry/vue";

import "../public/bootstrap.css";
import VueClipboard from "vue-clipboard2";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import "vue-slider-component/theme/antd.css";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "vue-datepicker-next/index.css";

import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import DOMPurify from "dompurify";

import moment from "moment";

import globalFunction from "./globalFunction.js";
import Big from "big.js";
import Decimal from "decimal.js";
import NumAbbr from "number-abbreviate";

import VueAppleLogin from "vue-apple-login";
import VueCookies from "vue-cookies";
import vuetify from "./plugins/vuetify";
import VueSocialSharing from "vue-social-sharing";

import { ERROR_CODES } from "./errorCodes";

const app = createApp({
  beforeCreate() {
    store.dispatch("auth/initializeAuthState");
    store.dispatch("preferences/initializePreferences");
  },

  created() {
    if (localStorage.getItem("jwt_key")) {
      store.dispatch("user/refreshToken").then(() => {
        if (store.state.user.email === "") {
          store.dispatch("user/getProfile");
        }
      });
    }

    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en");
    }
  },

  mounted() {
    if (store.state.auth.isLoggedIn) {
      store.dispatch("user/initializeUser");
    }
  },

  render: () => h(App),
});

app.use(VueCookies, { expires: "1d", secure: true });
app.use(VueSocialSharing);

// app.use(VueMeta);
app.use(VueClipboard);
app.use(VueAppleLogin, {
  clientId: "com.hata.exchange.service",
  scope: "email",
  redirectURI: process.env.VUE_APP_APPLE,
  state: Date().toString(),
  usePopup: true,
});

// CUSTOM COMPONENTS
app.component("TextInput", require("./components/input/TextInput.vue").default);
app.component(
  "PincodeInput",
  require("./components/input/PincodeInput.vue").default
);
app.component(
  "HavingTroubleModal",
  require("./components/modal/HavingTroubleModal.vue").default
);
app.component(
  "IconTextModal",
  require("./components/modal/IconTextModal.vue").default
);
app.component(
  "SocialSharingModal",
  require("./components/modal/SocialSharingModal.vue").default
);
app.component(
  "PhoneInput",
  require("./components/input/PhoneInput.vue").default
);
app.component(
  "TextareaInput",
  require("./components/input/TextareaInput.vue").default
);
app.component(
  "TxnHistoryDateInput",
  require("./components/input/TxnHistoryDateInput.vue").default
);
app.component(
  "BirthdateInput",
  require("./components/input/BirthdateInput.vue").default
);
app.component(
  "CountrySelect",
  require("./components/input/CountrySelect.vue").default
);
app.component(
  "StateSelect",
  require("./components/input/StateSelect.vue").default
);
app.component(
  "NavigationButton",
  require("./components/main/NavigationButton.vue").default
);
app.component(
  "IconTextWrapper",
  require("./components/main/IconTextWrapper.vue").default
);
app.component(
  "TransactionRow",
  require("./components/wallet/TransactionRow.vue").default
);
app.component(
  "TransactionDetails",
  require("./components/wallet/TransactionDetails.vue").default
);
app.component(
  "TxnNoDataContainer",
  require("./components/wallet/NoDataContainer.vue").default
);
app.component(
  "RemarkContainer",
  require("./components/main/RemarkContainer.vue").default
);
app.component(
  "SelectDropdown",
  require("./components/input/SelectDropdown.vue").default
);
app.component(
  "MultiSelectionInput",
  require("./components/AutoInvest/MultiSelectionInput.vue").default
);
app.component(
  "IndustrySelect",
  require("./components/input/IndustrySelect.vue").default
);
app.component(
  "VerificationView",
  require("./components/verification/Verification.vue").default
);
app.component(
  "SelectModal",
  require("./components/modal/SelectModal.vue").default
);
app.component(
  "SelectCryptoModal",
  require("./components/modal/SelectCryptoModal.vue").default
);
app.component(
  "SelectCheckboxModal",
  require("./components/modal/SelectCheckboxModal.vue").default
);
app.component(
  "MultiSelectCoinModal",
  require("./components/AutoInvest/MultiSelectCoinModal.vue").default
);
app.component(
  "AddressBookModal",
  require("./components/modal/AddressBookModal.vue").default
);
app.component(
  "VerificationModal",
  require("./components/modal/VerificationModal.vue").default
);
app.component("DatePicker", require("vue-datepicker-next").default);
app.component("InlineSvg", require("vue-inline-svg").default);
app.component("qr-code", require("vue-qrcode-component").default);
app.component("VueSlider", VueSlider);
app.component(
  "MinAmountModal",
  require("./components/modal/MinAmountModal.vue").default
);
app.config.globalProperties.$Big = Big;
app.config.globalProperties.$Decimal = Decimal;
app.config.globalProperties.$NumAbbr = NumAbbr;
app.config.globalProperties.$moment = moment;

const globalOptions = {
  mode: "auto",
};

app.config.globalProperties.$error = ERROR_CODES;

app.mixin(globalFunction);

if (!app.config.globalProperties.$isServer) {
  app.config.globalProperties.$cookies = VueCookies;
}

const packageJson = require("../package.json");
console.log(packageJson.version);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend(event) {
    if (event.exception && 
        event.exception.values && 
        event.exception.values[0].value.includes('Object captured as promise rejection')) {
      return null;
    }
    return event;
  },
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
});
app.use(store);
app.use(VueTelInput, globalOptions);
const purifier = DOMPurify.sanitize;
app.config.globalProperties.$sanitize = (dirty) => {
  return purifier(dirty, { ADD_ATTR: ["target"] });
};
app.use(router);
app.use(i18n);
app.use(vuetify);
app.mount("#app");

export { app };
