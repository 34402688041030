<template>
    <div
        class="footer-body"
        v-if="
            (
                $route.name != 'SignInView' &&
                $route.name != 'ForgotPassword' &&
                $route.name != 'SignUpView' &&
                $route.name != 'VerifyTwoFa' &&
                $route.name != 'EmailVerifiedView' &&
                $route.name != 'BindPhoneNumber' &&
                $route.name != 'SetSecureEmail' &&
                $route.name != 'UnderMaintenance' &&
                !$route.query.id &&
                (
                    $route.name &&
                    !$route.name.includes('KycView') &&
                    !$route.name.includes('BusinessKyc')
                )
            )
        "
        :style="{
            'background':
                theme === 'dark-theme' &&
                ($route.name && $route.name.includes('ExchangeView'))
                ? 'var(--primary100)'
                : 'var(--primary-color)'
            }"
    >
        <div class="footer">
            <div class="footer_row">
                <div class="footer-container image">
                    <img src="@/assets/images/header/hataLogo.png" width="100px" />
                </div>

                <div class="footer-wrapper">
                    <!-- COMPANY -->
                    <div class="footer-container">
                        <div class="footer-title">{{ $t("Footer.Company") }}</div>
                        <router-link v-for="item, index in companyList" :key="index" :to="{name: item.name}">
                        <button
                            class="footer-link"
                        >
                            {{ item.title }}
                        </button>
                    </router-link>
                    </div>

                    <!-- EXPLORE -->
                    <div class="footer-container">
                        <div class="footer-title">{{ $t("Header.Explore") }}</div>

                        <a
                            :href="item.link"
                            target="_blank"
                            class="footer-link"
                            v-for="item, index in exploreList"
                            :key="index"
                        >
                            {{ item.title }}
                        </a>
                    </div>

                    <!-- BUSINESS -->
                    <!-- <div class="footer-container">
                        <div class="footer-title">{{ $t("Header.Business") }}</div>
                        <div
                            class="footer-link"
                            v-for="item, index in businessList"
                            :key="index"
                        >
                            <router-link :to="item.link">{{ item.title }}</router-link>
                        </div>
                    </div> -->

                    <!-- LEGAL -->
                    <div class="footer-container">
                        <div class="footer-title">{{ $t("Footer.Legal") }}</div>
                        <router-link v-for="item, index in legalList" :key="index" :to="{name: item.name}">
                        <div
                            class="footer-link"
                        >
                            {{ item.title }}
                        </div>
                        </router-link>
                    </div>

                    <!-- COMPLIANCE -->
                    <div class="footer-container">
                        <div class="footer-title">{{ $t("Footer.Compliance") }}</div>
                        <router-link v-for="item, index in complianceList"
                            :key="index"
                            :to="{name: item.name}">
                        <div
                            class="footer-link"
                        >
                            {{ item.title }}
                        </div>
                    </router-link>
                    </div>

                    <!-- CONTACT -->
                    <div class="footer-container">
                        <div class="footer-title">{{ $t("Footer.Contact") }}</div>

                        <div class="footer-link pe-none">
                            <div class="address-content">{{ $t("Footer.address") }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="h-social-media">
                <a
                    v-for="item, index in socialMediaList"
                    :key="index"
                    :href="item.link"
                    target="_blank"
                >
                    <img :src="require(`@/assets/images/header/footer/${item.icon}`)" width="27px" />
                </a>
            </div>

            <div class="copyright-row">
                {{ $t("Footer.copyright") + currentYear + $t("Footer.copyright2") }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            currentYear: "",
            companyList: [
                { title: this.$t("Footer.AboutUs"), name: "Aboutus" },
                { title: this.$t("Footer.Fees"), name: "FeesView" },
                { title: this.$t("Footer.Affiliates"), name: "AffiliateView" },
                { title: this.$t("Announcements.Announcements"), name: "AnnouncementView"}
            ],

            exploreList: [
                { title: this.$t("Footer.Learn"), link: 'https://explore.hata.io/learn' },
                { title: this.$t("Footer.Careers"), link: 'https://explore.hata.io/careers' },
                // { title: this.$t("Footer.News"), link: 'https://explore.hata.io/news' },
                // { title: this.$t("Footer.MediaCoverage"), link: 'https://explore.hata.io/media-coverage' },
                { title: this.$t("Footer.HelpCentre"), link: 'https://support.hata.io' },
            ],

            businessList: [
                { title: this.$t("Header.OTC"), link: "/otc" }
            ],

            legalList: [
                { title: this.$t("Footer.TermOfUse"), name: "TermsOfUse" },
                { title: this.$t("Footer.PrivacyPolicy"), name: "PrivacyPolicy" },
                { title: this.$t("Footer.CookiePolicy"), name: "CookiePolicy" },
                { title: this.$t("RewardsPromotions.affiliateProgramme"), name: "RewardsPromotions" },
                { title: this.$t("AutoInvestPlan.autoInvest"), name: "AutoInvestTerms" },
                { title: this.$t("BuddyBonusLegal.buddyBonus"), name: "BuddyBonus" },
            ],

            complianceList: [
                { title: this.$t("Profile.security"), name: "CustodyView" },
                { title: this.$t("Footer.ComplianceInfo"), name: "ComplianceGuidelines" },
                { title: this.$t("Footer.RiskWarning"), name: "RiskWarning" },
                { title: this.$t("Footer.TravelRule"), name: "TravelRule" },
            ],

            socialMediaList: [
                { icon: "twitter.svg", link: "https://twitter.com/hataglobal" },
                { icon: "linkedin.svg", link: "https://www.linkedin.com/company/hataglobal/" },
                { icon: "tiktok.svg", link: "https://www.tiktok.com/@hataglobal" },
                { icon: "instagram.svg", link: "https://www.instagram.com/hataglobal/?next=%2F&hl=en" },
                { icon: "facebook.svg", link: "https://www.facebook.com/hataglobalofficial" },
                { icon: "youtube.svg", link: "https://www.youtube.com/@hataglobal/playlists" },
            ]
        }
    },

    computed: {
        theme() {
            return this.$store.state.preferences.theme;
        },
    },

    mounted: async function () {
        let d = new Date();
        this.currentYear = d.getFullYear();
    },
}
</script>

<style scoped>
.footer-body {
    background: var(--primary-color);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer {
    max-width: 1224px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 1rem 0%;
    width: 100%;
}

.footer_row {
    display: flex;
    flex-direction: row;
    padding: 2rem 0rem;
}

.footer-container {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}

.footer-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.footer-container .image {
    flex: 0 0 25%;
    max-width: 25%;
    align-self: center;
}

.footer-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    height: 30px;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
    cursor: default;
    text-align: left;
}

.footer-link {
    letter-spacing: 1px;
    font-size: 14px;
    margin: 5px 0;
    text-align: left;
    color: var(--gray40);
    cursor: pointer;
}

.footer-link:hover {
    color: var(--white);
}

.address-content {
    color: var(--gray40);
    max-width: 200px;
    white-space: break-spaces;
}

.footer-contact-row img {
    width: 20px;
}

.copyright-row {
    padding-top: 1rem;
    color: var(--gray30);
    justify-content: center;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    border-top: 1px solid var(--gray60);
}

.mobile-footer {
    background: var(--primary-color);
    height: 60px;
}

.h-social-media {
    padding-left: 1rem;
    padding-bottom: 1rem;
    display: flex;
    gap: 12px;
}

@media only screen and (max-width: 1200px) {
    .footer {
        padding: 1rem;
    }

    .footer_row {
        flex-direction: column;
    }

    .footer-container {
        padding-left: 0;
        white-space: break-spaces;
    }

    .footer-container .image {
        max-width: 100%;
        margin: 0 auto;
    }

    .footer-wrapper {
        margin: 20px 0;
        margin-bottom: 0px;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 12px;
    }

    .footer-contact-row .content {
        width: fit-content;
    }

    .copyright-row {
        padding: 1rem;
        text-align: center;
        font-size: 14px;
    }

    .footer_row {
        padding-bottom: 1rem;
    }

    .h-social-media {
        padding-left: 0rem;
    }
}

@media only screen and (max-width: 875px) {
    .footer-wrapper > .footer-container:nth-child(5) {
        border-top: 1px solid var(--gray60);
        width: 100%;
        margin-top: 1rem;
    }

    .footer-wrapper > .footer-container:nth-child(5) > .footer-title {
        margin-top: 1rem;
    }

    .address-content {
        max-width: 100%;
        white-space: normal;
    }

    .h-social-media {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 28px;
        padding-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 468px) {
    .footer_row {
        padding-bottom: 0rem;
    }

    .footer-wrapper > .footer-container:nth-child(3),
    .footer-wrapper > .footer-container:nth-child(4) {
        margin-top: 2rem;
    }

    .footer-wrapper > .footer-container:nth-child(5) {
        margin-bottom: 1rem;
    }

    .footer-container:nth-child(1),
    .footer-container:nth-child(3) {
        min-width: 120px;
    }

    .footer-container:nth-child(2),
    .footer-container:nth-child(4) {
        min-width: 180px;
    }

    .copyright-row {
        padding-bottom: 0;
        font-size: 12px;
    }
}
</style>